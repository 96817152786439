import React from 'react';
import BlockTitle from './BlockTitle';

import CtaShape1 from '../assets/images/shapes/cta-1-shape-1.png';
import CtaShape2 from '../assets/images/shapes/cta-1-shape-2.png';
import CtaMoc1 from '../assets/images/AboutUs.png';

const CTAOne = () => {
  return (
    <section className='cta-one' id='about'>
      <img src={CtaShape1} className='cta-one__bg-shape-1' alt='awesome post' />
      <img src={CtaShape2} className='cta-one__bg-shape-2' alt='awesome post' />
      <div className='container'>
        <div className='cta-one__moc wow fadeInLeft' data-wow-duration='1500ms'>
          <img src={CtaMoc1} className='cta-one__moc-img' alt='About Us' />
        </div>
        <div className='row justify-content-end'>
          <div className='col-lg-6'>
            <div className='cta-one__content'>
              <BlockTitle
                textAlign='left'
                paraText=''
                titleText={`About Us `}
              />
              <div className='cta-one__text'>
                <p>
                  At <strong>Insurge Technologies India Pvt. Ltd.</strong> we
                  are passionate about harnessing the power of technology to
                  drive innovation and solve complex problems.
                </p>
              </div>

              <div className='cta-one__text mt-n4'>
                <p>
                  <strong>Our mission</strong> is to empower organizations by
                  providing innovative software solutions that enhance
                  efficiency, drive growth, and streamline operations. We
                  believe that technology should be accessible and intuitive,
                  enabling our clients to focus on what they do best—growing
                  their business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAOne;

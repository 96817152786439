import React from 'react';
import BlockTitle from './BlockTitle';
import ContactBgShape from '../assets/images/shapes/contact-bg-shape-1-1.png';
import ContactImage from '../assets/images/ContactUs.png';

const Contact = () => {
  return (
    <section className='contact-one' id='contact'>
      <img
        src={ContactBgShape}
        className='contact-one__bg-shape-1'
        alt='awesome post'
      />
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7'>
            <form className='contact-form-validated contact-one__form'>
              <BlockTitle
                textAlign='left'
                paraText=''
                titleText={`Have Question? Write \n a Message`}
              />
              <div className='row'>
                <div className='col-lg-6'>
                  <input type='text' placeholder='Name' name='name' />
                </div>
                <div className='col-lg-6'>
                  <input type='text' placeholder='Email Address' name='email' />
                </div>
                <div className='col-lg-6'>
                  <input type='text' placeholder='Subject' name='subject' />
                </div>
                <div className='col-lg-6'>
                  <input type='text' placeholder='Phone Number' name='phone' />
                </div>
                <div className='col-lg-12'>
                  <textarea
                    placeholder='Write Message'
                    name='message'
                  ></textarea>
                </div>
                <div className='col-lg-12 text-left'>
                  <button type='submit' className='thm-btn contact-one__btn'>
                    <span>Send Message</span>
                  </button>
                </div>
              </div>
            </form>
            <div className='result'></div>
          </div>
          <div
            className='col-lg-5 d-flex wow fadeInRight'
            data-wow-duration='1500ms'
          >
            <div className='my-auto'>
              <div className='contact-one__image'>
                <img src={ContactImage} alt='awesome post' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

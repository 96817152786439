import React from 'react';
import BlockTitle from './BlockTitle';

import CTAImage1 from '../assets/images/Features.png';
import CTAImage2 from '../assets/images/resources/cta-2-moc-2.png';
import CTAImage3 from '../assets/images/resources/cta-2-moc-3.png';

const CTATwo = () => {
  return (
    <section className='cta-two ' id='features'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='cta-two__content'>
              <BlockTitle
                textAlign='left'
                paraText=''
                titleText={`Our Silent Features`}
              />
              <div className='cta-two__icon-wrap'>
                <div className='cta-two__icon-single'>
                  <div className='cta-two__icon'>
                    <i className='apton-icon-app-development'></i>
                  </div>
                  <h3>
                    Accurate <br />
                    data.
                  </h3>
                </div>
                <div className='cta-two__icon-single'>
                  <div className='cta-two__icon'>
                    <i className='apton-icon-computer-graphic1'></i>
                  </div>
                  <h3>
                    Responsive <br /> Design
                  </h3>
                </div>
              </div>
              <ul className='list-unstyled cta-one__list'>
                <li>
                  <i className='fa fa-check-circle'></i>
                  Navigate effortlessly to boost productivity from day one.
                </li>
                <li>
                  <i className='fa fa-check-circle'></i>
                  Seamlessly connects with existing systems for smooth
                  workflows.
                </li>
                <li>
                  <i className='fa fa-check-circle'></i>
                  Gain insights instantly to make informed decisions.
                </li>
                <li>
                  <i className='fa fa-check-circle'></i>
                  Tailor the software to fit your unique business needs.
                </li>
                <li>
                  <i className='fa fa-check-circle'></i>
                  Reduce manual tasks and errors for improved efficiency.
                </li>
                <li>
                  <i className='fa fa-check-circle'></i>
                  Enjoy dedicated support and regular updates to keep your
                  software effective.
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-6 '>
            <img
              src={CTAImage1}
              className='wow fadeInUp'
              data-wow-duration='1500ms'
              alt='awesome post'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTATwo;

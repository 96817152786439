import React from 'react';
import BlockTitle from './BlockTitle';

const Services = () => {
  return (
    <section className=' cta-one' id='services'>
      <div className='container'>
        <BlockTitle textAlign='center' paraText='' titleText={`Our Services`} />
        <div className='row'>
          <div className='col-lg-3 col-md-6'>
            <div className='service-one__single'>
              <div className='service-one__inner'>
                <div className='service-one__circle'></div>
                <div className='service-one__icon'>
                  <i className='  ri-dv-fill ' style={{ fontSize: '43px' }} />
                </div>
                <h3>Tel-MX</h3>
                <p>
                  TelMX offers a powerful solution for monitoring and improving
                  driving behavior. It tracks driving patterns, provides
                  real-time insights
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <div className='service-one__single'>
              <div className='service-one__inner'>
                <div className='service-one__circle'></div>
                <div className='service-one__icon'>
                  <i className='ri-car-fill' style={{ fontSize: '43px' }}></i>
                </div>
                <h3>ETS</h3>
                <p>
                  ETS is an advanced Employee Transport System designed to
                  streamline and enhance the transportation experience for
                  organizations.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <div className='service-one__single'>
              <div className='service-one__inner'>
                <div className='service-one__circle'></div>
                <div className='service-one__icon'>
                  <i className='ri-bill-fill' style={{ fontSize: '43px' }}></i>
                </div>
                <h3>POB</h3>
                <p>
                  Point of Billing (POB) is a robust billing solution designed
                  to simplify and enhance the transaction process for businesses
                  of all sizes.
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <div className='service-one__single'>
              <div className='service-one__inner'>
                <div className='service-one__circle'></div>
                <div className='service-one__icon'>
                  <i
                    className=' ri-taxi-wifi-fill'
                    style={{ fontSize: '43px' }}
                  ></i>
                </div>
                <h3>Spot Rental</h3>
                <p>
                  Dedicated solution designed to efficiently manage your spot
                  rental operations. Our software simplifies the rental process,
                  enabling businesses
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

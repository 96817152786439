import React from 'react';
import Herobg from '../assets/video/HeroBg.mp4';
import LogoImage from '../assets/images/InsurgeLogoWhite.png';
import Typewriter from 'typewriter-effect';

const Banner = () => {
  return (
    <section id='home'>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '90vh',
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        <video
          autoPlay
          muted
          loop
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: 'auto',
            zIndex: -1,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <source src={Herobg} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className='container h-100 d-flex justify-content-center align-items-center'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <div className='banner-one__content'>
                <img
                  src={LogoImage}
                  className='responsive_width'
                  alt='Insurge Logo'
                />
                <h4 className='text-white mt-3'>
                  <Typewriter
                    options={{
                      strings: [
                        'Transforming Ideas into Innovation.',
                        'Where Ideas Spark Innovation.',
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

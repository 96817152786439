import React, { useState } from 'react';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const FunFact = () => {
  const [counter, setCounter] = useState({
    startCounter: false,
  });

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setCounter({ startCounter: true });
    }
  };
  return (
    <section className='funfact-one py-0'>
      <div className='container'>
        <form action='#' className='mailchimp-one__form py-5'>
          <input
            type='text'
            placeholder='Enter your email address'
            name='email'
          />
          <button className='thm-btn mailchimp-one__btn' type='submit'>
            <span>Contact With Us</span>
          </button>
        </form>
      </div>
    </section>
  );
};

export default FunFact;
